











































import Vue from "vue";
import dayjs from "dayjs";

export default Vue.extend({
  props: {
    label: {
      required: false,
      type: String,
      default: () => ""
    },
    value: {
      required: true,
      type: String,
    },
    noPastDates: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      localValue: this.value,
      savedValue: this.value
    };
  },
  computed: {
    allowedDates(): (val: string) => boolean {
      return this.noPastDates ? (val) => {
        return dayjs().isBefore(val, 'day');
      } : () => true
    }
  },
  watch: {
    value(n, o) {
      this.localValue = n;
      this.savedValue = n;
    },
  },
  methods: {
    save() {
      this.savedValue = this.localValue;
      this.$emit("input", this.localValue);
      this.menu = false;
    },
  }
});
