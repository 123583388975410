


































































import Vue, { PropType } from "vue";
import {
  OptionsIssuancesAnalysisDto,
  OptionsIssuanceStatus,
} from "@/service/options-issuances/options-issuances.service.dto";
import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import Grid from "@/components/grid/Grid.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import {
  toActionableItem,
  ActionableItem,
  MenuActions,
} from "@/components/menu-actions";
import {
  deleteOptionsIssuanceDraft,
  getOptionsIssuancesAnalysisOfCompany,
} from "@/service/options-issuances/options-issuances.service";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

type Filter = "issued" | "exercised" | "draft" | "pending" | "vested" | "vesting"

const stateFilter = (filter: Filter) => (item: OptionsIssuancesAnalysisDto): boolean => {
  switch (filter) {
    case "issued":
      return item.status != "Drafted" && item.status != "Pending";
    case "draft":
      return item.status == "Drafted";
    case "pending":
      return item.status == "Pending";
    case "vesting":
      return item.vestingCount > 0;
    case "vested":
      return item.vestedCount > 0;
    case "exercised":
      return item.exercisedCount > 0;
  }
}

const COUNT_PROP: Record<Filter, keyof Pick<OptionsIssuancesAnalysisDto, "count" | "vestingCount" | "exercisedCount" | "vestedCount">> = {
  issued: "count",
  exercised: "exercisedCount",
  draft: "count",
  pending: "count",
  vested: "vestedCount",
  vesting: "vestingCount"
}

export default Vue.extend({
  components: {
    Grid,
    MenuActions,
    ConfirmDialog,
    ProgressBar
  },
  props: {
    status: {
      type: String as PropType<Filter>,
      required: true,
    },
    showVestingProgress: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      loading: false,

      defaultHeaders: [
        {
          text: this.$t("optionHolder").toString(),
          align: "start",
          sortable: false,
          value: "stakeholder",
        },
        {
          text: this.$t("pool").toString(),
          align: "start",
          sortable: false,
          value: "pool.name",
        },
        {
          text: this.$t("vestingDate").toString(),
          align: "start",
          sortable: false,
          value: "vestingEndDate",
        },
        {
          text: this.$t("exerciseBy").toString(),
          align: "start",
          sortable: false,
          value: "pool",
        },
        {
          text: this.$t("options").toString(),
          align: "start",
          sortable: false,
          value: "count",
        },
        {
          text: this.$t("percentage").toString(),
          align: "start",
          sortable: false,
          value: "companyPercentage",
        },
        {
          text: this.$t("vestingProgress").toString(),
          align: "start",
          sortable: false,
          value: "vestingProgress"
        },
        {
          text: "",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ],
      items: [] as Array<OptionsIssuancesAnalysisDto>,
      showConfirmDialog: false,
      selectedItem: undefined as OptionsIssuancesAnalysisDto | undefined,
    };
  },
  computed: {
    headers(): Array<any> {
      return this.defaultHeaders.filter((header) => {
        if (!this.showVestingProgress && header.value == "vestingProgress")
          return false;

        return true;
      })
    },
    actionableItems(): Array<ActionableItem<OptionsIssuancesAnalysisDto>> {
      return this.items.map((x) => {
        const itemView = toActionableItem(x);

        if (this.$can("delete", "OptionsIssuance")) {
          if (x.status == "Drafted") {
            itemView.actions.push({
              text: this.$t("shared.delete").toString(),
              clickHandler: this.onDelete,
            });
          }
        }

        return itemView;
      });
    },
    selectedItemName(): string {
      return this.selectedItem ? this.selectedItem.stakeholder.name : "-";
    },
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;

        this.items = (await getOptionsIssuancesAnalysisOfCompany(
          companiesStore.state.currentId
        )).filter(stateFilter(this.status));

        console.log(this.items)
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async onDelete(item: OptionsIssuancesAnalysisDto) {
      this.selectedItem = item;
      this.showConfirmDialog = true;
    },
    async onDeleteConfirm() {
      if (!this.selectedItem || !this.selectedItem.id) return;

      try {
        this.loading = true;
        await deleteOptionsIssuanceDraft(this.selectedItem.id);
        appStore.snackbar(
          this.$t("shared.successfulExecution").toString(),
          "success"
        );
        this.loading = false;
        this.loadData();
      } catch (error) {
        this.loading = false;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    getCountByStatus(item: OptionsIssuancesAnalysisDto): string {
      let count = item[COUNT_PROP[this.status]];
      return this.$n(count).toString();
    },
  },
});
