

































































































































































































import Vue from "vue";
import { MenuAction, MenuActions } from "@/components/menu-actions";
import Section from "@/components/Section.vue";
import { StakeholderDto } from "@/service/stakeholders/stakeholders.service.dto";
import {
  OptionsIssuancesAnalysisDto,
  OptionsIssuanceStatus,
} from "@/service/options-issuances/options-issuances.service.dto";
import { getOptionsIssuanceAnalysis } from "@/service/options-issuances/options-issuances.service";
import { appStore } from "@/app.store";
import { OptionsPoolDto } from "@/service/options-pools/options-pools.service.dto";

const statusTextMap: Record<OptionsIssuanceStatus, string> = {
  Drafted: "draft",
  Pending: "pending",
  Vesting: "vesting",
  Vested: "vested",
  Exercised: "exercised",
};

export default Vue.extend({
  components: {
    MenuActions,
    Section,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    actions: [] as Array<MenuAction>,
    optionHolder: undefined! as StakeholderDto,
    optionsIssuanceAnalysis: undefined! as OptionsIssuancesAnalysisDto,
    optionsPool: undefined! as OptionsPoolDto,
  }),
  async mounted() {
    await this.loadData();
    this.populateActions();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.optionsIssuanceAnalysis = await getOptionsIssuanceAnalysis(
          this.id
        );
        this.optionHolder = this.optionsIssuanceAnalysis.stakeholder;
        this.optionsPool = this.optionsIssuanceAnalysis.pool;
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    populateActions() {
      if (this.$can("update", "OptionsIssuance")) {
        this.actions.push({
          text: this.$t("cancelOptions").toString(),
          clickHandler: this.cancelOptions,
        });

        if (this.optionsIssuanceAnalysis.vestedCount > 0) {
          this.actions.push({
            text: this.$t("exerciseOptions").toString(),
            clickHandler: this.exerciseOptions,
          });
        }
        if(this.optionsIssuanceAnalysis.pool.type === 'Automatic' && this.optionsIssuanceAnalysis.status != "Pending"){
          this.actions.push({
            text: this.$t("Share Options Details").toString(),
            clickHandler: this.issuanceDetails,
          });
        }
        if(this.optionsIssuanceAnalysis.pool.type !== 'Automatic'){
          this.actions.push({
            text: this.$t("Vest Share Options").toString(),
            clickHandler: this.manualVest,
          });
        }
      }
    },
    cancelOptions() {
      this.optionHolder.id;
      this.$router.push({
        name: "cancel-options-issuance",
        params: { id: this.id },
      });
    },
    exerciseOptions() {
      this.optionHolder.id;
      this.$router.push({
        name: "exercise-options-issuance",
        params: { id: this.id },
      });
    },
    issuanceDetails(){
      this.$router.push({
        name: "view-options-issuance-details",
        params: {id: this.id}
      })
    },
    manualVest(){
      this.$router.push({
        name: "vest-options-issuance",
        params: {id: this.id}
      })
    },
    getStatusText(status: OptionsIssuanceStatus): string {
      return this.$t(statusTextMap[status]).toString();
    },
  },
});
