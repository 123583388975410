






import Vue from "vue";

export default Vue.extend({
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
  }
});
