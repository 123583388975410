















































































































































































































































































































import Vue, { PropType } from "vue";

import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import DateField from "@/components/DateField.vue";
import {
  getStakeholder,
  getStakeholdersOfCompany,
  StakeholderDto,
} from "@/service/stakeholders/stakeholders.service";
import {
  getOnePool,
  OptionsPoolDto,
} from "@/service/options-pools/options-pools.service";
import { AvailableOptionsExceededException, InvalidVestingStartDateException, CannotGiveOptionsToNonEmployeesException } from "@/service/options-issuances/options-issuances.service.dto";
import {
  createOptionsIssuance,
  createOptionsIssuanceDraft,
  getOptionsIssuanceAnalysis,
  updateOptionsIssuance,
  updateOptionsIssuanceDraft,
} from "@/service/options-issuances/options-issuances.service";
import ComplexConfirmDialog from "@/components/ComplexConfirmDialog.vue";
import { isApiError, isApiErrorOf } from "@/service";
import { DocumentDto } from "@/service/documents/documents.service.dto";
import { Filter, getDocumentsOf } from "@/service/documents/documents.service";

function startOfToday(): Date {
  return new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
}

export default Vue.extend({
  components: {
    DateField,
    ComplexConfirmDialog,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    poolId: {
      type: String,
      required: true,
    },
    filter: {
      type: Object as PropType<Filter>,
      required: false,
      default: undefined,
    }
  },
  data() {
    return {
      loading: false,
      stakeHolders: [] as Array<StakeholderDto>,
      selectedStakeholderId: undefined as string | undefined,
      selectedStakeholder: null as StakeholderDto | null,
      optionsSize: undefined as number | undefined,
      vestingStartDate: startOfToday().toISOString().substr(0, 10),
      pool: {} as OptionsPoolDto,
      saveAsDraft: !!this.id,
      showConfirmDialog: false,
      optionsAgreementFile: undefined as string | undefined,
      documents: [] as Array<DocumentDto>,
      documentTypeFilter: "" as DocumentType | ""
    };
  },
  computed: {
    isUpdateMode(): boolean {
      return !!this.id;
    },
    companySharePercent(): number {
        return (this.optionsSize! / companiesStore.state.current!.shares) * 100 || 0;
    },
    stakeHolderFullAddress(): string {
      return this.selectedStakeholder && this.selectedStakeholder.contact
        ? `${this.selectedStakeholder.contact.address.streetAddress}, ${this.selectedStakeholder.contact.address.city} ${this.selectedStakeholder.contact.address.state}, ${this.selectedStakeholder.contact.address.country}`
        : "";
    },
  },
  watch: {
    async selectedStakeholderId(newValue) {
      this.selectedStakeholder = await getStakeholder(newValue);
    },
  },
  async mounted() {
    if (!companiesStore.state.currentId) return;

    try {
      this.loading = true;

      if (this.isUpdateMode) {
        let result = await Promise.all([
          getStakeholdersOfCompany(companiesStore.state.currentId, ["contact"]),
          getOptionsIssuanceAnalysis(this.id),
        ]);

        this.stakeHolders = result[0];
        const issuance = result[1];

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.pool = await getOnePool(issuance.pool.id!);

        this.selectedStakeholderId = issuance.stakeholder.id;
        this.optionsSize = issuance.count;
        this.vestingStartDate = issuance.vestingStartDate
          .toISOString()
          .substr(0, 10);

        var docsResults = await Promise.all([
          getDocumentsOf(
            this.filter || {
            entity: "company",
            id: companiesStore.state.currentId,
          },
          this.documentTypeFilter == "" ? undefined : this.documentTypeFilter
          ),
        ]);
        this.documents = await docsResults[0];
      } else {
        let result = await Promise.all([
          getStakeholdersOfCompany(companiesStore.state.currentId, ["contact"]),
          getOnePool(this.poolId),
        ]);
        this.stakeHolders = result[0];
        this.pool = result[1];
        this.documents = await getDocumentsOf(
            this.filter || {
            entity: "company",
            id: companiesStore.state.currentId,
          },
          this.documentTypeFilter == "" ? undefined : this.documentTypeFilter
          );
      }
      
      this.loading = false;
    } catch (error) {
      appStore.snackbar(this.$t("shared.genericError").toString(), "error");
    }
  },
  methods: {
    async onCommitClick() {
      if (this.saveAsDraft) this.onConfirmClick();
      else this.showConfirmDialog = true;
    },
    async onConfirmClick() {
      try {
        this.loading = true;
        if (this.isUpdateMode) {
          if (this.saveAsDraft) {
            await updateOptionsIssuanceDraft({
              id: this.id,
              stakeholderID: this.selectedStakeholderId,
              count: this.optionsSize,
              status: "Drafted",
              vestingStartDate: new Date(this.vestingStartDate),
              poolId: this.poolId,
            });
          } else {
            if (!this.selectedStakeholderId || !this.optionsSize) return;

            await updateOptionsIssuance({
              id: this.id,
              stakeholderID: this.selectedStakeholderId,
              count: this.optionsSize,
              status: this.saveAsDraft ? "Drafted" : "Pending",
              grantDate: startOfToday(),
              vestingStartDate: new Date(this.vestingStartDate),
              poolId: this.poolId,
            });
          }
        } else {
          if (this.saveAsDraft) {
            await createOptionsIssuanceDraft({
              stakeholderID: this.selectedStakeholderId,
              count: this.optionsSize,
              status: "Drafted",
              vestingStartDate: new Date(this.vestingStartDate),
              poolId: this.poolId,
            });
          } else {
            if (!this.selectedStakeholderId || !this.optionsSize) return;
            await createOptionsIssuance({
              stakeholderID: this.selectedStakeholderId,
              count: this.optionsSize,
              status: this.saveAsDraft ? "Drafted" : "Pending",
              grantDate: startOfToday(),
              vestingStartDate: new Date(this.vestingStartDate),
              poolId: this.poolId,
              fileId: this.optionsAgreementFile
            });
          }
        }

        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        if (isApiErrorOf<AvailableOptionsExceededException>(error, "InvestockCoreApi.Services.Exceptions.AvailableOptionsExceededException")){
          appStore.snackbar(this.$t("availableOptionsExceededException").toString(), "error");
        }
        else if (isApiErrorOf<InvalidVestingStartDateException>(error, "InvestockCoreApi.Services.Exceptions.InvalidVestingStartDateException")){
          appStore.snackbar(this.$t("invalidVestingStartDateException", { years: error.minAllowedPastYears }).toString(), "error");
        }
        else if (isApiErrorOf<CannotGiveOptionsToNonEmployeesException>(error, "InvestockCoreApi.Services.Exceptions.CannotGiveOptionsToNonEmployeesException")){
          appStore.snackbar(this.$t("CannotGiveOptionsToNonEmployeesException").toString(), "error");
        }
        else {
          appStore.snackbar(this.$t("shared.genericError").toString(), "error");
        }
      }
    },
  },
});
