export { default as MenuActions } from "./MenuActions.vue";
export interface MenuActionFor<TItem> {
    text: string;
    clickHandler: (item: TItem) => void;
}

export interface MenuAction {
    text: string;
    clickHandler: () => void;
}

export type ActionableItem<TItem> =  TItem & { actions: Array<MenuActionFor<TItem>> }

export function toActionableItem<TItem>(item: TItem, actions: Array<MenuActionFor<TItem>> = []): ActionableItem<TItem> {
    return {
        ...item,
        actions: actions
    }
}