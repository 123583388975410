







































































import Vue from "vue";
import { MenuAction, MenuActions } from "@/components/menu-actions";
import Section from "@/components/Section.vue";
import { OptionsIssuancesAnalysisDto } from "@/service/options-issuances/options-issuances.service.dto";
import {
  getOptionsIssuanceAnalysis,
  cancelOptionsIssuance,
} from "@/service/options-issuances/options-issuances.service";
import { appStore } from "@/app.store";
import { OptionsPoolDto } from "@/service/options-pools/options-pools.service.dto";

type CancelType = "all" | "partial";

export default Vue.extend({
  components: {
    MenuActions,
    Section,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    processing: false,
    actions: [] as Array<MenuAction>,
    cancelType: "all" as CancelType,
    partialSize: "",
    boardResolutionFile: undefined as File | undefined,
    optionsIssuanceAnalysis: undefined! as OptionsIssuancesAnalysisDto,
    optionsPool: undefined! as OptionsPoolDto,
  }),
  computed: {
    inputPartialOptionsRules(): any {
      return this.cancelType == "partial"
        ? {
            required: true,
            numeric: true,
            min_value: 1,
            max_value: this.optionsIssuanceAnalysis.vestingCount
          }
        : undefined;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.optionsIssuanceAnalysis = await getOptionsIssuanceAnalysis(
          this.id
        );
        this.optionsPool = this.optionsIssuanceAnalysis.pool;
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async commit() {
      if (!this.boardResolutionFile) return;

      try {
        this.processing = true;
        await cancelOptionsIssuance({
          id: this.id,
          count:
            this.cancelType == "all"
              ? this.optionsIssuanceAnalysis.count - this.optionsIssuanceAnalysis.vestedCount
              : parseInt(this.partialSize),
          boardResolutionFile: this.boardResolutionFile,
        });
        this.processing = false;
        appStore.snackbar(
          this.$t("shared.successfulExecution").toString(),
          "success"
        );
        this.$router.go(-1);
      } catch (error) {
        this.processing = false;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async changeType(cancelTypeText: CancelType){
        this.cancelType = cancelTypeText;
    },
  },
});
