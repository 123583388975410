var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Section',{attrs:{"title":_vm.$t('optionsIssuances')}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('Grid',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":5,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [10, 20, 50] },"search":""},scopedSlots:_vm._u([{key:"item.stakeholder",fn:function(ref){
var item = ref.item;
return [(!item.stakeholder)?_c('span',[_vm._v(_vm._s(_vm.$t("notSelected")))]):(item.status == 'Drafted')?_c('router-link',{attrs:{"to":{
              name: 'create-options-issuance',
              query: { id: item.id },
            }}},[_vm._v(" "+_vm._s(item.stakeholder.name)+" ")]):_c('router-link',{attrs:{"to":{
              name: 'view-options-issuance',
              params: { id: item.id },
            }}},[_vm._v(" "+_vm._s(item.stakeholder.name)+" ")])]}},{key:"item.vestingEndDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(item.vestingEndDate))+" ")]}},{key:"item.pool",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("yearsAfterTheVestingDate", { years: Math.floor(item.pool.exerciseDuration / 12), }))+" ")]}},{key:"item.companyPercentage",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.companyPercentage))+" % ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }