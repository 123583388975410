














































































































































































































import Vue from "vue";

import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import DateField from "@/components/DateField.vue";
import ComplexConfirmDialog from "@/components/ComplexConfirmDialog.vue";
import { OptionsIssuancesAnalysisDto } from "@/service/options-issuances/options-issuances.service.dto";
import { exerciseOptionsIssuance, getOptionsIssuanceAnalysis } from "@/service/options-issuances/options-issuances.service";
import { StakeholderDto } from "@/service/stakeholders/stakeholders.service.dto";

function startOfToday(): Date {
  return new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
}

export default Vue.extend({
  components: {
    DateField,
    ComplexConfirmDialog
  },
  props: {
    id: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    return {
      loading: false,
      optionsIssuance: null as OptionsIssuancesAnalysisDto | null,
      stakeholder: null as StakeholderDto | null,
      optionsSize: undefined as number | undefined,
      exerciseDate: startOfToday().toISOString().substr(0, 10),
      directorFile: undefined as File | undefined,
      shareHolderFile: undefined as File | undefined,
      showConfirmDialog: false
    };
  },
  computed: {
    companySharePercent(): number {
      return ((this.optionsSize! / companiesStore.state.current!.shares) * 100) || 0;
    },
    stakeHolderFullAddress(): string {
      return this.stakeholder && this.stakeholder.contact
        ? `${this.stakeholder.contact.address.streetAddress}, ${this.stakeholder.contact.address.city} ${this.stakeholder.contact.address.state}, ${this.stakeholder.contact.address.country}`
        : "";
    },
    totalPrice(): number {
      return this.optionsSize ? this.optionsSize * this.optionsIssuance!.pool.exercisePrice : 0;
    },
    availableToExercise(): number {
      return (this.optionsIssuance?.vestedCount ?? 0) - (this.optionsIssuance?.exercisedCount ?? 0)
    },
    inputOptionsSizeRules(): any {
      return {
        required: true,
        numeric: true,
        min_value: 1,
        max_value: this.availableToExercise
      }
    },
  },
  async mounted() {
    if (!companiesStore.state.currentId) return;

    try {
      this.loading = true;
      this.optionsIssuance = await getOptionsIssuanceAnalysis(this.id);
      this.stakeholder = this.optionsIssuance.stakeholder;
      this.loading = false;
    } catch (error) {
      appStore.snackbar(this.$t("shared.genericError").toString(), "error");
    }
  },
  methods: {
    async onCommitClick() {
      this.showConfirmDialog = true;
    },
    async onConfirmClick() {
      try {
        this.loading = true;


        await exerciseOptionsIssuance({
          id: this.id,
          optionsSize: this.optionsSize!,
          exerciseDate: new Date(this.exerciseDate),
          directorFile:
            this.directorFile && this.directorFile.size > 0
              ? this.directorFile
              : undefined,
          shareholderFile:
            this.shareHolderFile && this.shareHolderFile.size > 0
              ? this.shareHolderFile
              : undefined,
        });

        // invalidate current company's shares count
        await companiesStore.fetchList();

        appStore.snackbar(
          this.$t("shared.successfulExecution").toString(),
          "success"
        );
        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
  },
});
