


































import Vue, { PropType } from "vue";
import { MenuAction } from ".";

export default Vue.extend({
  props: {
    context: {
      required: false,
      type: Object,
      default: undefined,
    },
    actions: {
      required: true,
      type: Array as PropType<Array<MenuAction>>,
    },
    inline: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
});
