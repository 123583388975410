







































































































import Section from "@/components/Section.vue";
import {
  OptionsIssuanceDetailsDto,
  OptionsIssuanceDetailDataDto,
  OptionsIssuanceStatus
} from "@/service/options-issuances/options-issuances.service.dto";
import { getOptionsIssuanceDetails } from "@/service/options-issuances/options-issuances.service";
import { appStore } from "@/app.store";

const statusTextMap: Record<OptionsIssuanceStatus, string> = {
  Drafted: "draft",
  Pending: "pending",
  Vesting: "vesting",
  Vested: "vested",
  Exercised: "exercised",
};


import Grid from "@/components/grid/Grid.vue";

import Vue from "vue";

export default Vue.extend({
  components: {
    Section,
    Grid,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t("Instalment Size").toString(),
          align: "start",
          sortable: false,
          value: "installmentCount",
        },
        {
          text: this.$t("Status").toString(),
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: this.$t("Vested").toString(),
          align: "start",
          sortable: false,
          value: "vestedCount",
        },
        {
          text: this.$t("Exercised").toString(),
          align: "start",
          sortable: false,
          value: "exercisedCount",
        },
        {
          text: this.$t("Expired").toString(),
          align: "start",
          sortable: false,
          value: "expiredCount",
        },
        {
          text: this.$t("Vesting at").toString(),
          align: "start",
          sortable: false,
          value: "vestingDate",
        },
        {
          text: this.$t("Vested at").toString(),
          align: "start",
          sortable: false,
          value: "vestedDate",
        },
        {
          text: this.$t("Excercised at").toString(),
          align: "start",
          sortable: false,
          value: "excerciseDate",
        },
        {
          text: this.$t("Expires at").toString(),
          align: "start",
          sortable: false,
          value: "expirationdateDate",
        },
        {
          text: "",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ],
      items: [] as Array<OptionsIssuanceDetailDataDto>,
      genericDetails : undefined! as OptionsIssuanceDetailsDto
    };
  },
  watch: {
    filter() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        var result = await getOptionsIssuanceDetails(this.id);
        this.genericDetails = result;
        this.items = result.details;
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    getStatusText(status: OptionsIssuanceStatus): string {
      return this.$t(statusTextMap[status]).toString();
    },
  },
});
