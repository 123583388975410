
































































































































































































































































































































































import Vue, { PropType } from "vue";

import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import { isApiErrorOf, optionsPoolsService, shareClassesService } from "@/service";
import { MaxPoolLimitExceededdException, PoolNameAlreadyExistsException } from "@/service/options-pools/options-pools.service.dto";
import ComplexConfirmDialog from "@/components/ComplexConfirmDialog.vue";
import { DocumentDto } from "@/service/documents/documents.service.dto";
import { Filter, getDocumentsOf } from "@/service/documents/documents.service";

export default Vue.extend({
  components: {
    ComplexConfirmDialog
  },
  props: {
    filter: {
      type: Object as PropType<Filter>,
      required: false,
      default: undefined,
    }
  },
  data() {
    return {
      step: 1,
      pool: {
        name: "",
        size: undefined as number | undefined,
        restrictions: "",
        directorFile: undefined as string | undefined,
        shareholderFile: undefined as File | undefined,
        vestingDuration: 12,
        vestingPercent: 100,
        exercisePrice: 0,
        exerciseDuration: 36,
        shareClassId: "",
        type: "",
      },
      loading: false,
      showConfirmDialog: false,
      shareClasses: [] as Array<shareClassesService.ShareClassDto>,
      poolOptionTypes: ["Automatic","Manual",this.$t("Manual KPI")],
      CurrentType: 'Automatic',
      noDocsProvided: false,
      documents: [] as Array<DocumentDto>,
      documentTypeFilter: "" as DocumentType | ""
    };
  },
  computed: {
    totalVestingPeriod(): string {
      var years =
        ((100 / this.pool.vestingPercent) * this.pool.vestingDuration) / 12;
      return years > 0 && years !== Infinity ? years.toFixed(2) : "?";
    },
  },
  async mounted() {
    this.step = 1;

    if (!companiesStore.state.currentId) return;

    try {
      this.loading = true;
      this.shareClasses = await shareClassesService.getAllShareClassesOfCompany(
        companiesStore.state.currentId
      );
      this.documents = await getDocumentsOf(
            this.filter || {
            entity: "company",
            id: companiesStore.state.currentId,
          },
          this.documentTypeFilter == "" ? undefined : this.documentTypeFilter
          );
      this.loading = false;
    } catch (error) {
      appStore.snackbar(this.$t("shared.genericError").toString(), "error");
    }
  },
  methods: {
    async create() {
      if (!companiesStore.state.currentId) return;
      this.showConfirmDialog = true
      //this.onConfirmClick();
    },
    async onConfirmClick(){
      try {
        console.log(this.pool.directorFile);
        if (this.pool.directorFile == null && this.pool.shareholderFile == null) {
          this.noDocsProvided = true;
        }
        this.loading = true;
        console.log("before");
        await optionsPoolsService.createOptionsPoolOfCompany(companiesStore.state.currentId, {
          name: this.pool.name,
          optionsTotal: this.pool.size ?? 0,
          optionsRemaining: this.pool.size ?? 0,
          directorFile: this.pool.directorFile,
          shareholderFile: this.pool.shareholderFile,
          vestingDuration: this.pool.vestingDuration,
          vestingPercent: this.pool.vestingPercent,
          rules: this.pool.restrictions,
          exercisePrice: this.pool.exercisePrice,
          status: "Pending",
          exerciseDuration: this.pool.exerciseDuration,
          shareClassId: this.pool.shareClassId,
          type: this.pool.type
        });
        console.log("after");
        appStore.snackbar(this.$t("created").toString(), "success");
        this.$router.replace({ path: "/options/pools" });
      } catch (error) {
        if (isApiErrorOf<PoolNameAlreadyExistsException>(error, "InvestockCoreApi.Services.Exceptions.PoolNameAlreadyExistsException")){
          appStore.snackbar(this.$t("poolNameAlreadyExistsException").toString(), "error");
        }
        else if (isApiErrorOf<MaxPoolLimitExceededdException>(error, "InvestockCoreApi.Services.Exceptions.MaxPoolLimitExceededdException")){
          appStore.snackbar(this.$t("maxPoolLimitExceededdException", { percentage: error.maxAllowedPercent }).toString(), "error");
        }
        else if(this.noDocsProvided){
          appStore.snackbar(this.$t("shared.documentRequired").toString(), "error");
        }
        else {
          appStore.snackbar(this.$t("shared.genericError").toString(), "error");
        }
        
      } finally {
        this.loading = false;
      }
    },
    async ChangeType(){
      this.CurrentType = this.pool.type;
    }
  },
});
