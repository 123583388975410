










import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    percentFormatted(): string {
      return `${this.$n((Math.ceil(this.value * 100)))}%`
    },
    width(): string {
      return `${this.value * 100}%`
    }
  }
});
