



































import Vue from "vue";
import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import {
  deletePool,
  getPoolsOfCompany,
  OptionsPoolDto,
  OptionsPoolStatus,
} from "@/service/options-pools/options-pools.service";
import {
  toActionableItem,
  ActionableItem,
  MenuActions,
} from "@/components/menu-actions";
import Grid from "@/components/grid/Grid.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

const statusTextMap: Record<OptionsPoolStatus, string> = {
  Live: "live",
  Pending: "pendingApproval",
};

export default Vue.extend({
  components: {
    MenuActions,
    Grid,
    ConfirmDialog,
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t("pool").toString(),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("total").toString(),
          align: "start",
          sortable: false,
          value: "optionsTotal",
        },
        {
          text: this.$t("remaining").toString(),
          align: "start",
          sortable: false,
          value: "optionsRemaining",
        },
        {
          text: this.$t("status").toString(),
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: "",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ],
      items: [] as Array<OptionsPoolDto>,
      showConfirmDialog: false,
      selectedPool: { name: "" } as OptionsPoolDto | undefined,
    };
  },
  computed: {
    actionableItems(): Array<ActionableItem<OptionsPoolDto>> {
      return this.items.map((x) => {
        const itemView = toActionableItem(x);

        if (this.$can("delete", "OptionsPool") && x.status == "Pending") {
          itemView.actions.push({
            text: this.$t("shared.delete").toString(),
            clickHandler: this.onDeletePool,
          });
        }

        if (this.$can("create", "OptionsIssuance") && x.status != "Pending") {
          itemView.actions.push({
            text: this.$t("grantOptions").toString(),
            clickHandler: this.onGrantOptions,
          });
        }

        return itemView;
      });
    },
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        var result = await getPoolsOfCompany(companiesStore.state.currentId);
        this.items = result;
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    onGrantOptions(poolItem: OptionsPoolDto) {
      this.$router.push({
        path: "/options/grant-options",
        query: { poolId: poolItem.id },
      });
    },
    async onDeletePool(poolItem: OptionsPoolDto) {
      this.selectedPool = poolItem;
      this.showConfirmDialog = true;
    },
    async onDeletePoolConfirm() {
      if (!this.selectedPool) return;

      try {
        this.loading = true;
        await deletePool(this.selectedPool.id);
        appStore.snackbar(
          this.$t("shared.successfulExecution").toString(),
          "success"
        );
        this.loading = false;
        this.loadData();
      } catch (error) {
        this.loading = false;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    getStatusText(status: OptionsPoolStatus): string {
      return this.$t(statusTextMap[status]).toString();
    },
  },
});
