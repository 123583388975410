var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.genericDetails)?_c('Section',{attrs:{"title":_vm.$t('optionsIssuanceDetails')}},[_c('v-container',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("optionHolder")))]),_c('router-link',{attrs:{"to":{ path: ("/stakeholders/" + (_vm.genericDetails.stakeholder.id)) }}},[_vm._v(" "+_vm._s(_vm.genericDetails.stakeholder.name)+" ")])],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("totalOptions")))]),_c('div',{staticClass:"body-1"},[_vm._v(" "+_vm._s(_vm.$n(_vm.genericDetails.totalCount))+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("exercisePrice")))]),_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.$n(_vm.genericDetails.unitPrice, 'currency')))])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("issuanceDate")))]),_c('div',{staticClass:"body-1"},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.genericDetails.grantDate)))+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("vestingStartDate")))]),_c('div',{staticClass:"body-1"},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.genericDetails.vestingStartDate)))+" ")])])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('Grid',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.installmentCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.installmentCount)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")]}},{key:"item.vestedCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vestedCount)+" ")]}},{key:"item.exercisedCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.exercisedCount)+" ")]}},{key:"item.expiredCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.expiredCount)+" ")]}},{key:"item.vestingDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.vestingDate)))+" ")]}},{key:"item.vestedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.vestedDate !== null) ? _vm.$d( new Date(item.vestedDate)) :'')+" ")]}},{key:"item.excerciseDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.excerciseDate !== null) ? _vm.$d( new Date(item.excerciseDate)) :'')+" ")]}},{key:"item.expirationdateDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.expirationdateDate)))+" ")]}}],null,false,3580197105)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }