





























import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    message: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      code: "",
      randomCode: Math.floor(Math.random() * 8999 + 1000).toString(),
    };
  },
  computed: {
    opened: {
      get(): boolean {
        return this.value;
      },
      set(v: boolean): void {
        this.$emit("input", v);
      },
    },
    isCodeCorrect(): boolean {
      return this.code == this.randomCode;
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.opened = false;
    },
    cancel() {
      this.$emit("cancel");
      this.opened = false;
    },
  },
});
