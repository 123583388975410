










import Vue from "vue";
import ListViewOptions from "./ListViewOptions.vue"

export default Vue.extend({
  components: {
    ListViewOptions
  }
});
