



















































import Vue from "vue";
import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";

import Section from "@/components/Section.vue";
import Grid from "@/components/grid/Grid.vue";
import { OptionsIssuancesDto } from "@/service/options-issuances/options-issuances.service.dto";
import { getOptionsIssuancesAnalysisOfStakeholder } from "@/service/options-issuances/options-issuances.service";

export default Vue.extend({
  components: {
    Section,
    Grid,
  },
  props: {
    optionHolderId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t("optionHolder").toString(),
          align: "start",
          sortable: false,
          value: "stakeholder",
        },
        {
          text: this.$t("pool").toString(),
          align: "start",
          sortable: false,
          value: "pool.name",
        },
        {
          text: this.$t("vestingDate").toString(),
          align: "start",
          sortable: false,
          value: "vestingEndDate",
        },
        {
          text: this.$t("exerciseBy").toString(),
          align: "start",
          sortable: false,
          value: "pool",
        },
        {
          text: this.$t("options").toString(),
          align: "start",
          sortable: false,
          value: "count",
        },
        {
          text: this.$t("percentage").toString(),
          align: "start",
          sortable: false,
          value: "companyPercentage",
        },
      ],
      items: [] as Array<OptionsIssuancesDto>,
    };
  },
  async mounted() {
    if (!companiesStore.state.currentId) return;

    try {
      this.loading = true;
      this.items = await getOptionsIssuancesAnalysisOfStakeholder(this.optionHolderId);
      this.loading = false;
    } catch (error) {
      appStore.snackbar(this.$t("shared.genericError").toString(), "error");
    }
  },
});
