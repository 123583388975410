























































































































import Vue from "vue";
import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import {
  deletePool,
  getOnePool,
  OptionsPoolDto,
} from "@/service/options-pools/options-pools.service";
import {
  getStakeholdersOfPool,
  StakeholderDto,
} from "@/service/stakeholders/stakeholders.service";
import { MenuAction, MenuActions } from "@/components/menu-actions";
import Section from "@/components/Section.vue";
import Grid from "@/components/grid/Grid.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default Vue.extend({
  components: {
    MenuActions,
    Section,
    Grid,
    ConfirmDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      pool: { name: "" } as OptionsPoolDto,
      headers: [
        {
          text: this.$t("optionHolder").toString(),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("total").toString(),
          align: "start",
          sortable: false,
          value: "total",
        },
        {
          text: this.$t("draft").toString(),
          align: "start",
          sortable: false,
          value: "draft",
        },
        {
          text: this.$t("pending").toString(),
          align: "start",
          sortable: false,
          value: "pending",
        },
        {
          text: this.$t("vested").toString(),
          align: "start",
          sortable: false,
          value: "vested",
        },
        {
          text: this.$t("exercised").toString(),
          align: "start",
          sortable: false,
          value: "exercised",
        },
      ],
      items: [] as Array<StakeholderDto>,
      actions: [] as Array<MenuAction>,
      showConfirmDialog: false,
    };
  },
  async mounted() {
    await this.fetchData();
    this.populateActions();
  },
  methods: {
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        var results = await Promise.all([
          getOnePool(this.id),
          getStakeholdersOfPool(this.id, ["options-overview", "contact"]),
        ]);
        this.pool = results[0];
        this.items = results[1];
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    onGrantOptions() {
      this.$router.push({
        path: "/options/grant-options",
        query: { poolId: this.id },
      });
    },
    async onDeletePool() {
      this.showConfirmDialog = true;
    },
    async onDeletePoolConfirm() {
      try {
        this.loading = true;
        await deletePool(this.id);
        appStore.snackbar(
          this.$t("shared.successfulExecution").toString(),
          "success"
        );
        this.loading = false;
        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    populateActions() {
      if (this.$can("delete", "OptionsPool") && this.pool.status == "Pending") {
        this.actions.push({
          text: this.$t("shared.delete").toString(),
          clickHandler: this.onDeletePool,
        });
      }

      if (
        this.$can("create", "OptionsIssuance") &&
        this.pool.status != "Pending"
      ) {
        {
          this.actions.push({
            text: this.$t("grantOptions").toString(),
            clickHandler: this.onGrantOptions,
          });
        }
      }
    },
  },
});
