var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5 min-height-1"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("optionsModule.viewCreatePoolPrompt")))])])],1),_c('v-row',[_c('v-responsive',[_c('v-stepper',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.step > 1,"step":"1"}},[_vm._v(" "+_vm._s(_vm.$t("shared.stepNumber", { number: 1 }))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.step > 2,"step":"2"}},[_vm._v(" "+_vm._s(_vm.$t("shared.stepNumber", { number: 2 }))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.step > 3,"step":"3"}},[_vm._v(" "+_vm._s(_vm.$t("shared.stepNumber", { number: 3 }))+" ")])],1),_c('v-stepper-items',[_c('v-container',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("optionsModule.poolAttributes"))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"poolName","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('optionsModule.poolName'),"error-messages":errors},model:{value:(_vm.pool.name),callback:function ($$v) {_vm.$set(_vm.pool, "name", $$v)},expression:"pool.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"poolSize","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('optionsModule.poolSize'),"error-messages":errors},model:{value:(_vm.pool.size),callback:function ($$v) {_vm.$set(_vm.pool, "size", $$v)},expression:"pool.size"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.poolOptionTypes,"item-text":"name","item-value":"name","label":_vm.$t('poolOptionTypes'),"error-messages":errors},on:{"change":_vm.ChangeType},model:{value:(_vm.pool.type),callback:function ($$v) {_vm.$set(_vm.pool, "type", $$v)},expression:"pool.type"}})]}}],null,true)})],1)],1),(_vm.CurrentType === 'Automatic')?_c('v-row',{staticClass:"grey lighten-4"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"vestingDuration","rules":"required|numeric|min_value:1|max_value:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('optionsModule.vestingPeriod'),"error-messages":errors},model:{value:(_vm.pool.vestingDuration),callback:function ($$v) {_vm.$set(_vm.pool, "vestingDuration", $$v)},expression:"pool.vestingDuration"}},[_c('v-tooltip',{attrs:{"slot":"prepend","top":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("optionsModule.vestingPeriodTooltip")))])])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"vestingPercent","rules":"required|vestingPercent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('optionsModule.vestingPercent'),"error-messages":errors},model:{value:(_vm.pool.vestingPercent),callback:function ($$v) {_vm.$set(_vm.pool, "vestingPercent", _vm._n($$v))},expression:"pool.vestingPercent"}},[_c('v-tooltip',{attrs:{"slot":"prepend","top":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("optionsModule.vestingPercentTooltip")))])])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"pl-8 text-caption grey--text text--darken-3"},[_vm._v(" "+_vm._s(_vm.$t("optionsModule.totalVestingPeriod"))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-1 d-inline"},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("optionsModule.totalVestingPeriodTooltip")))])]),_c('div',{staticClass:"grey-darken-1--text d-inline"},[_vm._v(" "+_vm._s(_vm.totalVestingPeriod)+" ")])],1)])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"poolName","rules":"required|min_value:0.01"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('exercisePrice'),"error-messages":errors},model:{value:(_vm.pool.exercisePrice),callback:function ($$v) {_vm.$set(_vm.pool, "exercisePrice", $$v)},expression:"pool.exercisePrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"exerciseDuration","rules":"required|numeric|min_value:1|max_value:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('exerciseDuration'),"error-messages":errors},model:{value:(_vm.pool.exerciseDuration),callback:function ($$v) {_vm.$set(_vm.pool, "exerciseDuration", $$v)},expression:"pool.exerciseDuration"}},[_c('v-tooltip',{attrs:{"slot":"prepend","top":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("exerciseDurationTooltip")))])])],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"min-width-2 mr-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t("shared.cancel"))+" ")]),_c('v-btn',{staticClass:"min-width-2",attrs:{"color":"secondary","depressed":""},on:{"click":function($event){_vm.step = 2}}},[_vm._v(" "+_vm._s(_vm.$t("shared.next"))+" ")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',{staticClass:"my-2"},[_c('v-col',[_c('validation-provider',{attrs:{"name":"shareClass","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.shareClasses,"item-text":"name","item-value":"id","label":_vm.$t('shareClass'),"error-messages":errors},model:{value:(_vm.pool.shareClassId),callback:function ($$v) {_vm.$set(_vm.pool, "shareClassId", $$v)},expression:"pool.shareClassId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t(
                              'optionsModule.createPool.restrictionsSummaryLabel'
                            )},model:{value:(_vm.pool.restrictions),callback:function ($$v) {_vm.$set(_vm.pool, "restrictions", $$v)},expression:"pool.restrictions"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"min-width-2 mr-2",attrs:{"outlined":""},on:{"click":function($event){_vm.step = 1}}},[_vm._v(" "+_vm._s(_vm.$t("shared.previous"))+" ")]),_c('v-btn',{staticClass:"min-width-2",attrs:{"color":"secondary","depressed":""},on:{"click":function($event){_vm.step = 3}}},[_vm._v(" "+_vm._s(_vm.$t("shared.next"))+" ")])],1)],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("optionsModule.uploadResolutionPrompt"))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"flex-fill",attrs:{"items":_vm.documents,"item-text":"name","item-value":"id","label":_vm.$t('optionsModule.directorShareholderResolution'),"truncate-length":"20","error-messages":errors},model:{value:(_vm.pool.directorFile),callback:function ($$v) {_vm.$set(_vm.pool, "directorFile", $$v)},expression:"pool.directorFile"}},[_c('v-tooltip',{attrs:{"slot":"prepend","top":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("optionsModule.documentInstuctions")))])])],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-file-input',{attrs:{"label":_vm.$t('optionsModule.shareholderResolution'),"truncate-length":"20","error-messages":errors},model:{value:(_vm.pool.shareholderFile),callback:function ($$v) {_vm.$set(_vm.pool, "shareholderFile", $$v)},expression:"pool.shareholderFile"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-10"},[_c('v-col',[_c('v-btn',{staticClass:"min-width-2 mr-2",attrs:{"outlined":""},on:{"click":function($event){_vm.step = 2}}},[_vm._v(" "+_vm._s(_vm.$t("shared.previous"))+" ")]),_c('v-btn',{staticClass:"min-width-2",attrs:{"color":"secondary","depressed":"","disabled":invalid,"loading":_vm.loading},on:{"click":_vm.create}},[_vm._v(" "+_vm._s(_vm.$t("create"))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)]}}])}),_c('ComplexConfirmDialog',{attrs:{"message":_vm.$t('confirmCreatePoolMessage')},on:{"confirm":_vm.onConfirmClick},model:{value:(_vm.showConfirmDialog),callback:function ($$v) {_vm.showConfirmDialog=$$v},expression:"showConfirmDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }