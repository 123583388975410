








































































































































































































import { appStore } from "@/app.store";
import { companiesStore } from "@/service/companies/companies.store";
import Vue from "vue";
import Widget from "@/components/Widget.vue";
import { getPoolsOverviewOfCompany, OptionsPoolsOverviewDto } from "@/service/options-pools/options-pools.service";
import * as userService from "@/service/user/user.service";


export default Vue.extend({
  components: {
    Widget
  },
  data() {
    return {
      overview:{
        optionsInPools: 0,
        optionsInDraft: 0,
        pendingAcceptance: 0,
        vested: 0,
        vesting: 0,
      } as OptionsPoolsOverviewDto,
      loading: true,
      isFree: true,
      error: "",
      valid: false,
      subject: "I am ready to unleash the power of equity",
      body: "Hello OptIn, \n\nI would like to upgrade my subscription...",
      email: ""
    };
  },
  computed:{
    optionsIssued(): number {
      return this.overview.vesting + this.overview.vested
    }
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        this.overview = await getPoolsOverviewOfCompany(companiesStore.state.currentId);
        if(companiesStore.state.current !== undefined){
          this.isFree = companiesStore.state.current.isFree;
        }
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async ContactUs() {
      try {
        this.loading = true;
        this.error = "";
        await userService.sendContactUsMail({subject: this.subject,body: this.body,cc: this.email});
        this.$router.replace("/");
      } catch (error) {
        this.error = "Failed to send email";
      }
      this.loading = false;
    }
  },
});
