










import Vue from "vue";
import { MenuAction, MenuActions } from "@/components/menu-actions";
import Section from "@/components/Section.vue";
import { companiesStore } from "@/service/companies/companies.store";

export default Vue.extend({
  components: {
    MenuActions,
    Section,
  },
  data: () => ({
    actions: [] as Array<MenuAction>,
  }),
  mounted() {
    this.populateActions();
  },
  methods: {
    populateActions() {
      if (this.$can("create", "Valuation"))
        this.actions.push({
          text: this.$t("optionsModule.addValuation").toString(),
          clickHandler: this.createValuation,
        });

      if (this.$can("create", "OptionsPool"))
        if(!companiesStore.state.current?.isFree){
          this.actions.push({
            text: this.$t("optionsModule.createAnOptionPool").toString(),
            clickHandler: this.createPool,
          });
        }
        

      if (this.$can("create", "OptionsIssuance")) {
        if(!companiesStore.state.current?.isFree){
          this.actions.push({
            text: this.$t("grantOptions").toString(),
            clickHandler: this.grantOptions,
          });
        }
        }
    },
    createValuation() {
      this.$router.push({ path: "/company/valuations/create" });
    },
    createPool() {
      this.$router.push({ path: "/options/create" });
    },
    grantOptions() {
      this.$router.push({ path: "/options/pools" });
    },
    
  },
});
